import { useEffect, useState } from 'react';
import io from 'socket.io-client';

let socket;

export default function useWebsocket() {
  const [action, setAction] = useState({ name: null, call_id: '' });

  useEffect(() => {
    if (socket) {
      socket.on('action', receiveAction);
    }
    return () => {
      if (socket) {
        socket.off('action', receiveAction);
      }
    };
  }, [socket]);

  const disconnect = () => {
    if (socket) {
      socket.disconnect();
    }
  };

  const connect = agent_name => {
    socket = io(process.env.REACT_APP_AI_API_URL, { transports: ['websocket'] });
    socket.connect();
    socket.emit('action', { action: 'register_agent', name: agent_name });
  };

  function receiveAction(value) {
    setAction(value);
  }

  const handleIncomingCall = (accept_call, call_id) => {
    const newMessage = {
      action: 'accept_call',
      accept_call: accept_call,
      call_id: call_id,
    };
    socket.emit('action', newMessage);
  };

  return { action, handleIncomingCall, disconnect, connect };
}
